@import "./theme.scss";

.scale-on-hover {
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.02);
  }
}

.fade-in-comp {
  &.delayed {
    animation-delay: 0.5s;
  }
  animation: fade-in-animation-to-bottom;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-in-comp-alt {
  animation: fade-in-animation-to-top;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-to-left {
  animation: fade-in-animation-to-left;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-to-right {
  animation: fade-in-animation-to-right;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-to-bottom {
  animation: fade-in-animation-to-bottom;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-animation-to-bottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in-animation-to-top {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes fade-in-animation-to-right {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes fade-in-animation-to-left {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

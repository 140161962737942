@import "../../../../styles/theme.scss";
@import "../../../../styles/mixins.scss";

$card-color: $color-2;
$animation-duration: 1000ms; // ms

.ProblemSolutionImageCard {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  box-shadow: $main-box-shadow;
  border-radius: $main-border-radius;
  background-color: $card-color;
  &__header {
    @include make-card($card-color);
    box-shadow: none;
    border-bottom: 1px solid $color-3;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &--title {
      text-align: center;
      margin: 0.5rem 0;
    }
  }
  &__body {
    &--container {
      @include make-card($card-color);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      border-radius: 0;
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
    }
    width: fit-content;
    position: relative;
    &--item {
      &.show {
        transition-delay: calc(($animation-duration / 2) - 10ms);
      }
      transition-property: transform;
      transition-duration: calc($animation-duration / 2);
      transition-timing-function: linear;
    }
  }
  &__problem {
    &.hide {
      transform: rotateY(90deg);
    }
    &.show {
      transform: rotateY(0deg);
    }
  }
  &__solution {
    &.hide {
      transform: rotateY(-90deg);
    }
    &.show {
      transform: rotateY(0deg);
    }
    position: absolute;
    top: 0;
    left: 0;
  }
  &__footer {
    @include make-card($card-color);
    box-shadow: none;
    border-top: 1px solid $color-3;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: grid;
  }
}

@import "../../../styles/theme.scss";
@import "../../../styles/mixins.scss";

.SideBar {
  width: 100%;
  height: 100%;
  background-color: $main-background;
  padding-top: 25px;
  & > nav {
  }
  & ul {
    padding-inline-start: 20px;
  }
  & li {
    list-style: none;
    padding: 1rem;
    font-size: 1.5rem;
    & > a {
      cursor: pointer;
      color: $font-color;
      text-decoration: none;
      @include underline-on-hover();
    }
  }
}

$color-1: #0d1b2a;
$color-2: #1b263b;
$color-3: #415a77;
$color-4: #778da9;
$color-5: #e0e1dd;
$text-background-color: rgba(0, 0, 0, 0.507);

$font-color: $color-5;
$icon-color: $color-5;
$main-background: $color-1;

$main-box-shadow: 1px 1px 4px 1px $text-background-color;
$thin-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.87);
$main-border-radius: 0.4rem;
